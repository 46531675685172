<template>
  <div class="guide">
    <div class="title">
      <span>{{ title }}</span>
    </div>
    <div class="message">
      <span>
        <slot></slot>
      </span>
    </div>
    <div class="guide-image">
      <img :src="require(`../assets/${image}`)" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Guide",
  props: ["type", "title", "image"],
};
</script>

<style lang="scss" scoped>
.guide {
  overflow: hidden;
  line-height: 1;
  .title {
    font-family: "GothamSSm";
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.266px;
  }
  .message {
    font-family: "Noto Sans KR";
    font-size: 15px;
    line-height: 24px;
    margin-top: 8px;
  }
  .guide-image {
    margin-top: 20px;
    img {
      width: 242px;
    }
  }
}
</style>
